import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import { StyledLink } from '../components/Buttons/Button'
import Seo from 'gatsby-plugin-wpgraphql-seo';

import linkedin from '../images/icon-linkedin-dark.png'


const Leadership = ({data}) => {
  const {
    title,
    content,
    leadership,
  } = data.wpLeadership

  //console.log(data.wpLeadership)


  return (
    <Layout title={title}>
      <Seo post={data.wpLeadership} />
      <div className="container pt-8 pb-20">
        <StyledLink to="/about" arrow="left" noOutline>BACK TO LEADERSHIP</StyledLink>
        <header className="mb-12">
          <h1 className="mb-2 mt-4">{title}</h1>
          <h3 className="uppercase flex">
            {leadership.title}
            {leadership.linkedin && (
              <ul className="list-none flex m-0 border-l pl-3 ml-3">
                <li>
                  <a href={leadership.linkedin} className="block" target="_blank" rel="noreferrer">
                    <img className="w-6" src={linkedin} alt="LinkedIn"/>
                  </a>
                </li>
              </ul>
            )}
          </h3>
        </header>
        <div className="grid grid-cols-12 gap-8 col">
          <div className="col-span-12 md:col-span-7 order-2 md:order-1" dangerouslySetInnerHTML={{__html: content}} />
          <div className="col-span-12 md:col-span-5 text-purpleOverlay order-1 md:order-2">
            {/*  */}
            {leadership.headshot ? (
              <img src={leadership.headshot.sourceUrl} alt={leadership.headshot.title}/>
            ) : (
              <img src="https://via.placeholder.com/1016x1180" alt="Placeholder"/>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpLeadership(id: { eq: $id }) {
      id
      title
      content
      leadership {
        title
        linkedin
        headshot {
          title
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`

export default Leadership
